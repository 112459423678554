import React from "react";

import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function IndexPage() {
  return (
    <Layout headerTransparent={true}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Design &amp; Technology Services for Entrepreneurs"
      />

      <header
        className="bg-black min-h-screen flex flex-col relative"
        style={{ marginTop: -80 }}
      >
        <div
          className="bg-image"
          style={{
            backgroundImage: `url(${require("../images/world.jpg")})`,
            backgroundAttachment: "static",
            backgroundPosition: "center",
          }}
        ></div>
        <div
          className="bg-overlay"
          style={{
            background:
              "linear-gradient(-90deg, rgba(0,0,0,0), rgba(0,0,0,0.6)",
          }}
        ></div>
        <div className="flex-1 flex items-center">
          <div className="container">
            <div className="text-center md:mt-48">
              <h1
                className="text-white md:text-6xl whitespace-no-wrap"
                style={{ fontSize: "calc(1rem + 8vw)" }}
              >
                <span className="font-bold">McFarlane</span>
                <span className="font-light">Tech</span>
              </h1>
              {/* <h2 className="font-light text-3xl md:text-2xl text-white">
                <strong className="font-bold">Design</strong> <span>&amp;</span>{" "}
                <strong className="font-bold">Technology</strong> Services
              </h2> */}
              <h2
                className="font-light md:text-4xl text-white"
                style={{ fontSize: "calc(1rem + 5vw)", lineHeight: 1.1 }}
              >
                We Help Creative{" "}
                <strong className="font-bold">Entrepreneurs</strong>
                <br />
                <strong className="font-bold">Build</strong> &amp;{" "}
                <strong className="font-bold">Grow</strong> Successful{" "}
                <strong className="font-bold">Businesses.</strong>
              </h2>
              {/* <Link
                className="btn bg-white rounded-full shadow-lg px-8 py-4 leading-tight"
                to="/contact"
              >
                Bring <strong>YOUR</strong> idea to life →
              </Link> */}
            </div>
          </div>
        </div>
        <div className="text-white hidden lg:block">
          <div className="container flex justify-between uppercase py-6 text-xs">
            <span>Web Design &amp; Development.</span>
            <span>Web Apps.</span>
            <span>Mobile Apps.</span>
            <span>Branding &amp; Identity Design.</span>
            <span>Digital Marketing.</span>
            <span>Quantum Mindset Training™.</span>
          </div>
        </div>
      </header>

      <section
        id="mission"
        className="section bg-white py-32"
        style={{
          background: "linear-gradient(#fff, #eee)",
        }}
      >
        <div className="max-w-2xl mx-auto text-center px-3">
          <h2 className="text-center">Our Mission.</h2>
          <p className="text-xl">
            Our mission is to help you bring your vision to life.
          </p>
          <p className="text-xl">
            With our 15 years of Design and Development experience, the latest{" "}
            Digital Technology, and our brand new Quantum Mindset Training™, we
            aim to serve and support you and your vision, and help you build a
            successful, thriving business online.
          </p>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="text-xl p-3">
            <FontAwesomeIcon
              icon={["fas", "check-circle"]}
              className="mr-1 text-green-700"
            />
            <span className="font-bold">Outstanding Service</span>
          </div>
          <div className="text-xl p-3">
            <FontAwesomeIcon
              icon={["fas", "check-circle"]}
              className="mr-1 text-green-700"
            />
            <span className="font-bold">Exceptional Quality</span>
          </div>
          <div className="text-xl p-3">
            <FontAwesomeIcon
              icon={["fas", "check-circle"]}
              className="mr-1 text-green-700"
            />
            <span className="font-bold">Unrivaled Support</span>
          </div>
        </div>
      </section>

      <section
        id="process"
        className="section bg-white py-32 bg-gray-900 relative"
      >
        <div
          className="bg-image xl:bg-fixed"
          style={{
            backgroundImage: `url(${require("../images/world.jpg")})`,
            backgroundPosition: "center",
          }}
        ></div>
        <div
          className="bg-overlay"
          style={{
            background: "linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,1)",
          }}
        ></div>

        <div className="max-w-2xl mx-auto text-center px-3 relative">
          <h2 className="text-center text-white font-light">
            Your Vision.
            <br />
            <strong className="font-bold">Our Proven Process.</strong>
          </h2>
          <p className="text-xl text-white">
            Our process is simple, yet powerful. It all starts with you and your
            vision. We take these core elements and combine them with our unique
            proven process, our essential business strategies, our powerful
            mindset training, and the latest digital technology to help you make
            your dream a reality.
          </p>
          <p className="text-xl text-white">
            We are here to serve and support you, every step of the way.
          </p>
        </div>
      </section>

      <section
        id="services"
        className="section py-32 bg-white"
        style={
          {
            // background: "linear-gradient(#fff, #eee)",
            // backgroundColor: "#F7F7FB",
          }
        }
      >
        <div className="max-w-2xl mx-auto text-center px-3">
          <h2 className="text-center">Our Speciality.</h2>
          <p className="text-xl">
            We specialize in user-friendly Websites, powerful Web Apps, rich and
            engaging Mobile Apps, and <em>custom-built</em> SaaS software—with
            security, performance and scalability in mind.
          </p>
          <p className="text-xl">
            ...In other words, we&apos;ve got you covered.
          </p>
        </div>
        <div className="max-w-3xl mx-auto">
          <div className="md:flex justify-center">
            <div className="flex-1 text-xl flex p-3 flex-col text-center items-center">
              <FontAwesomeIcon
                icon={["fas", "check-circle"]}
                className="m-1 text-blue-700"
              />
              <span className="font-bold">User-friendly Websites</span>
            </div>
            <div className="flex-1 text-xl flex p-3 flex-col text-center items-center">
              <FontAwesomeIcon
                icon={["fas", "check-circle"]}
                className="m-1 text-blue-700"
              />
              <span className="font-bold">Powerful Web Apps</span>
            </div>
            <div className="flex-1 text-xl flex p-3 flex-col text-center items-center">
              <FontAwesomeIcon
                icon={["fas", "check-circle"]}
                className="m-1 text-blue-700"
              />
              <span className="font-bold">Rich &amp; Engaging Mobile Apps</span>
            </div>
            <div className="flex-1 text-xl flex p-3 flex-col text-center items-center">
              <FontAwesomeIcon
                icon={["fas", "check-circle"]}
                className="m-1 text-blue-700"
              />
              <span className="font-bold">Custom-built SaaS Software</span>
            </div>
          </div>
        </div>
      </section>

      <section
        id="services"
        className="section py-32"
        style={{
          backgroundImage: `url(${require("../images/iphone.jpg")})`,
          backgroundSize: "auto 100%",
          backgroundPosition: "right bottom",
          backgroundAttachment: "static",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#F7F7FB",
        }}
      >
        <div
          className="xl:hidden absolute top-0 right-0 bottom-0 left-0"
          style={{ opacity: 0.8, backgroundColor: "#F7F7FB" }}
        ></div>
        <div className="container">
          <div className="text-center xl:text-left xl:flex">
            <div className="xl:w-1/2">
              <h2 className="font-light text-5xl xl:text-6xl">
                State of the art
                <br />
                <strong className="font-bold">iOS</strong> &amp;{" "}
                <strong className="font-bold">Android</strong>{" "}
                <span className="xl:whitespace-no-wrap">App Development</span>.
              </h2>
              <p className="text-xl">
                We provide a wide range of specialist services, including:
              </p>
              <div className="text-lg">
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-blue-700"
                  />
                  <span>iOS Development</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-blue-700"
                  />
                  <span>Android Development</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-blue-700"
                  />
                  <span>REST &amp; GraphQL API Development</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-blue-700"
                  />
                  <span>Cloud Web Service Integrations</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-blue-700"
                  />
                  <span>Mobile UX Design</span>
                </div>

                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-blue-700"
                  />
                  <span>QR Code and NFC Implementation</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-blue-700"
                  />
                  <span>Mobile Payments</span>
                </div>
              </div>
            </div>
            <div className="xl:w-1/2"></div>
          </div>
        </div>
      </section>

      <section
        id="services"
        className="section py-32 bg-gray-900 text-white xl:bg-fixed"
        style={{
          backgroundImage: `url(${require("../images/macbook-2.jpg")})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div
          className="bg-overlay"
          style={{
            background:
              "linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.7), rgba(0,0,0,1))",
          }}
        ></div>
        <div className="container">
          <div className="">
            <div className="text-center">
              <h2 className="font-light text-5xl xl:text-6xl text-white">
                Powerful
                <br />
                <strong className="font-bold">Web Apps</strong> &amp; <br />
                <strong className="font-bold">Websites.</strong>
              </h2>
              <p className="text-xl text-white">
                We provide a wide range of specialist web services, including:
              </p>
              <div className="text-lg">
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Bespoke Web Design &amp; Development</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>
                    Responsive Design for Mobile, Tablet &amp; Desktop
                  </span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Logo &amp; Identity Design</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Blazing Fast Websites</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Feature-rich Web Applications</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Powerful SaaS Software</span>
                </div>

                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Progressive Web Apps (PWA)</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Search Engine Optimisation (SEO)</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>UX Design</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>E-Commerce, Online Payments &amp; Subscriptions</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>REST &amp; GraphQL APIs</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Cloud Web Service Integrations</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Database Design &amp; Enterprise Architecture</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Zero-Downtime Migrations</span>
                </div>
                <div className="mb-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-600"
                  />
                  <span>Digital Marketing &amp; Ad Campaign Management</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-center max-w-xl mx-auto font-light">
            What our <strong className="font-bold">wonderful</strong> clients
            are saying...
          </h2>
          <div className="lg:flex flex-wrap justify-center text-center text-lg">
            <div className="lg:w-1/3 p-5">
              <p className="mb-0">
                <FontAwesomeIcon
                  icon={["fas", "quote-left"]}
                  className="text-gray-700"
                />{" "}
                I am very pleased with the re-design of my website by
                McFarlaneTech. For a while I did not feel connected with my
                previous website—it was slow and looked outdated. My
                instructions for a sophisticated, fast-loading, dynamic website
                have been followed to a tee and I could not be happier, thank
                you so much.{" "}
                <FontAwesomeIcon
                  icon={["fas", "quote-right"]}
                  className="text-gray-700"
                />
              </p>
              <div className="text-3xl text-center mt-3">
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
              </div>
            </div>

            <div className="lg:w-1/3 p-5">
              <p className="mb-0">
                <FontAwesomeIcon
                  icon={["fas", "quote-left"]}
                  className="text-gray-700"
                />{" "}
                McFarlaneTech&apos;s dedication and passion for what they do are
                exemplified by their unrivaled customer support and
                attentiveness to the specific needs of my business. They met all
                of my expectations and had a lot of insight regarding marketing
                tools I didn&apos;t know existed. To me, creating and building a
                website can be so stressful, but with McFarlaneTech, it never
                was. It was fun and exciting.{" "}
                <FontAwesomeIcon
                  icon={["fas", "quote-right"]}
                  className="text-gray-700"
                />
              </p>
              <div className="text-3xl text-center mt-3">
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
              </div>
            </div>
            <div className="lg:w-1/3 p-5">
              <p className="mb-0">
                <FontAwesomeIcon
                  icon={["fas", "quote-left"]}
                  className="text-gray-700"
                />{" "}
                McFarlaneTech were so patient and kind. We went over the design
                on the phone and in-person and they would send immediate
                updates. My website turned out, just as I dreamed. Mere words
                cannot describe my 100% appreciation for McFarlaneTech in
                developing a professional and user-friendly website.{" "}
                <FontAwesomeIcon
                  icon={["fas", "quote-right"]}
                  className="text-gray-700"
                />
              </p>
              <div className="text-3xl text-center mt-3">
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
              </div>
            </div>
            <div className="lg:w-1/3 p-5">
              <p className="mb-0">
                <FontAwesomeIcon
                  icon={["fas", "quote-left"]}
                  className="text-gray-700"
                />{" "}
                McFarlaneTech immediately helped me actualize my vision, while
                being supportive, caring and encouraging. They genuinely made me
                feel confident knowing they were connected and invested in my
                vision throughout the entire process. Highly Recommend!{" "}
                <FontAwesomeIcon
                  icon={["fas", "quote-right"]}
                  className="text-gray-700"
                />
              </p>
              <div className="text-3xl text-center mt-3">
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="footer"
        className="section bg-gray-200 py-48 flex items-center"
      >
        <div
          className="bg-image"
          style={{
            backgroundImage: `url(${require("../images/mountain.jpg")})`,
            backgroundPosition: "center 25%",
          }}
        ></div>
        <div
          className="bg-overlay"
          style={{
            background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.1))",
          }}
        ></div>

        <div className="container">
          <div className="mx-auto text-center">
            <h3 className="max-w-2xl mx-auto text-white font-light text-4xl md:text-5xl">
              Are you ready to embark on a{" "}
              <strong className="font-bold">new adventure</strong>?
            </h3>
            {/* <p className="max-w-3xl mx-auto text-white">
              <strong className="font-bold">Click the button below</strong> to
              get started:
            </p> */}
            <p className="mb-0">
              <Link
                className="btn inline-flex items-center justify-between bg-white rounded-full shadow-lg px-8 py-4 leading-tight text-xs sm:text-base"
                to="/contact"
              >
                <span></span>
                <span>
                  <strong>YES!</strong>{" "}
                  <span>I want to bring my idea to life</span>
                </span>
                <span className="ml-1">→</span>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
